<template>
  <div>
    <topbar drawer :title="navbarTitle"></topbar>
    <v-container v-if="!loading">
      <v-row dense>
        <v-col cols="12" sm="8">
          <div class="text-h3 text-truncate">{{manufacturer.name}}</div>
          <v-row dense class="pl-1 mt-4">
            <v-col cols="12" sm="8">
              <div class="mb-auto">Bekannt für: {{manufacturer.known_for}}</div>
            </v-col>
            <v-col cols="12" sm="8" class="mt-auto">
              <div class="mt-auto">{{manufacturer.description}}</div>
            </v-col>
            <!-- <v-col cols="12" sm="4">
              <v-img v-if="manufacturer && manufacturer.images" max-height="250" cover :src="require(`../assets/images/manufacturers/${manufacturer.images.logo}`)"></v-img>
            </v-col> -->
          </v-row>
        </v-col>
        <v-col cols="12" sm="4" class="d-flex align-center">
          <v-img v-if="manufacturer && manufacturer.images" cover :src="require(`../assets/images/manufacturers/${manufacturer.images.logo}`)"></v-img>
        </v-col>
      </v-row>
    <v-divider class="mt-4"/>
    </v-container>
    <v-container>
      <manufacturer-ship-list-item :document="ships" />
    </v-container>
  </div>
</template>

<script>
  import Topbar from '../components/Topbar';
  import ManufacturerShipListItem from '../components/ManufacturerShipListItem.vue';

  export default {
    name: 'ManufacturerDetail',

    components: {
      Topbar,
      ManufacturerShipListItem
    },

    props: {
      manufacturerId: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        navbarTitle: 'Manufacturer detail'
      };
    },

    computed: {
      loading() {
        return this.$store.getters.loading;
      },
      user() {
        return this.$store.getters['user/getUser'];
      },
      manufacturer() {
        return this.$store.getters['manufacturers/getManufacturer'];
      },
      ships() {
        return this.$store.getters['manufacturers/getShips'];
      },
    },

    beforeMount() {
      this.loadItems();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },

    methods: {
      async loadItems() {
        this.$store.dispatch('loading', true, {root: true});
        if (!this.user.userId) {
          await this.$store.dispatch('user/getUser', this.$auth && this.$auth.user.sub);
        }
        await this.$store.dispatch('manufacturers/getManufacturer', this.manufacturerId);
        await this.$store.dispatch('manufacturers/getShips', this.manufacturerId);
        this.$store.dispatch('loading', false, {root: true});
      }
    }
  };
</script>

<style scoped>
</style>
