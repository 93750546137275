<template>
  <v-container fluid>
  <div v-if="document.length">
    <v-row dense>
      <v-col v-for="(ship, index) in document" :key="index" cols="12" sm="6" md="6" lg="3" xl="2">
        <v-card>
          <v-img :src="ship.image" class="white--text align-end" height="200px">
            <div style="background:rgba(0,0,0, 0.4);">
              <v-hover v-slot="{ hover }">
                <v-card-title :class="{ 'custom-anchor': hover }" class="text-truncate" style="max-width:260px; display:block; cursor: pointer;" @click="shipDetail(ship.shipId)">{{ship.name}}</v-card-title>
              </v-hover>
              <v-card-subtitle style="cursor: pointer;">{{ship.manufacturerName}}</v-card-subtitle>
            </div>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <div v-if="!document.length" class="d-flex justify-center mt-8">
    <div>No Ship Found</div>
  </div>
  </v-container>
</template>

<script>
  export default {
    name: 'NavItem',

    props: {
      document: {
        type: Array,
        default: () => {
          return [];
        }
      }
    },

    methods: {
      shipDetail(shipId) {
        this.$router.push({name: 'ShipDetail', query: {shipId}});
      }
    }
  };
</script>

<style lang="scss" scoped>
.custom-anchor {
  color: skyblue;
  text-decoration: underline;
}
</style>
